import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const avatarStyles = cva(
	'rounded-full relative first-child:rounded-full transition-all ease-in-out block text-mauve1 dark:text-mauve12',
	{
		variants: {
			size: {
				sm: 'h-6 w-6 text-xs',
				md: 'h-8 w-8 text-sm',
				lg: 'h-10 w-10 text-sm',
				xl: 'h-12 w-12 text-base',
				'2xl': 'h-16 w-16 text-base',
				'3xl': 'h-20 w-20 text-lg',
				'4xl': 'h-24 w-24 text-xl',
				'5xl': 'h-32 w-32 text-3xl',
			},
			color: {
				neutral: 'text-mauve11 bg-mauve3',
				primary: 'text-violet11 bg-violet3',
				secondary: 'text-plum11 bg-plum3',
				success: 'text-green11 bg-green3',
				warning: 'text-yellow11 bg-yellow3',
				danger: 'text-red11 bg-red3',
				info: 'text-blue11 bg-blue3',
			},
		},
		defaultVariants: {size: 'md', color: 'neutral'},
	},
);

export type AvatarVariantProps = VariantProps<typeof avatarStyles>;
