'use client';

import {JobBoardLoadingItem} from './loading-item';

export const JobBoardLoading = () => (
	<div className="h-full flex-grow overflow-hidden p-[1px]">
		<div className="flex w-full flex-col gap-3">
			{Array.from({length: 15}).map((_, index) => (
				<JobBoardLoadingItem key={index} />
			))}
		</div>
	</div>
);
