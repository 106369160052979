import {JOB_TYPE} from '~/shared/constants/job';
import {PREFFIX_TO_FILTER_KEY} from '~/shared/constants/job-board';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';
import {isObject} from 'lodash';

import type {JobType as JobDataApiJobType} from '@job-ish/job-data-api/client/job-data-api';

export const isFilterValueDefined = (
	key: string,
	value: string | string[] | number | null | boolean | undefined,
) => {
	if (Array.isArray(value)) {
		return value.length > 0;
	}

	if (isObject(value)) {
		return Object.keys(value).length > 0;
	}

	if (typeof value === 'string') {
		return value.length > 0;
	}

	if (typeof value === 'number') {
		return value > 0;
	}

	return value !== null && value !== undefined;
};

export const getParsedDescription = (description: string) => {
	try {
		const parsedDescription = (JSON.parse(description) as string[]).map(term =>
			term.replaceAll('"', '').trim(),
		);

		if (!Array.isArray(parsedDescription)) {
			throw new TypeError('Invalid description');
		}

		const negatedTerms = parsedDescription
			.filter(term => term.startsWith(PREFFIX_TO_FILTER_KEY.exclude))
			.map(term => `-"${term.slice(1)}"`);
		const includedTitles = parsedDescription.filter(term => term.startsWith(PREFFIX_TO_FILTER_KEY.title));
		const includedKeywords = parsedDescription.filter(term => term.startsWith(PREFFIX_TO_FILTER_KEY.keyword));

		const titles = includedTitles.map(term => term.replace(PREFFIX_TO_FILTER_KEY.title, '')).join('|OR|');

		const keywords = includedKeywords
			.map(term => term.replace(PREFFIX_TO_FILTER_KEY.keyword, ''))
			.map(term => (negatedTerms.length > 0 ? `"${term}" ${negatedTerms.join(' ')}` : `"${term}"`))
			.join(' OR ');

		return {
			titles,
			keywords: includedKeywords.length > 0 ? keywords : negatedTerms.join(' '),
		};
	} catch {
		return {};
	}
};

export const getParsedJobBoardFilter = (filterCookie?: string | null) => {
	if (!filterCookie) {
		return {} as JobBoardFilterConfig;
	}

	try {
		const parsedFilter = JSON.parse(filterCookie) as Record<string, unknown>;

		return {
			...parsedFilter,
			description: Array.isArray(parsedFilter.description)
				? parsedFilter.description
				: parsedFilter.description
					? JSON.parse(parsedFilter.description as string)
					: undefined,
			maxAge: parsedFilter.maxAge ? Number(parsedFilter.maxAge) : undefined,
			companyMaxSize: parsedFilter.companyMaxSize ? Number(parsedFilter.companyMaxSize) : undefined,
			companyIndustryId: parsedFilter.companyIndustryId ? Number(parsedFilter.companyIndustryId) : undefined,
			companyId: parsedFilter.companyId ? Number(parsedFilter.companyId) : undefined,
			regionId: parsedFilter.regionId ? Number(parsedFilter.regionId) : undefined,
			minSalary: parsedFilter.minSalary ? Number(parsedFilter.minSalary) : undefined,
		} as JobBoardFilterConfig;
	} catch {
		return {} as JobBoardFilterConfig;
	}
};

export const getJobType = (jobType: JobDataApiJobType) => {
	switch (jobType.name) {
		case 'Full Time': {
			return JOB_TYPE.FullTime;
		}
		case 'Part Time': {
			return JOB_TYPE.PartTime;
		}
		case 'Temporary': {
			return JOB_TYPE.Contract;
		}
		case 'Internship': {
			return JOB_TYPE.Internship;
		}
		default: {
			return JOB_TYPE.Other;
		}
	}
};

export const getCompanyMinSize = (companyMaxSize: number) => {
	if (companyMaxSize === 1) return 2001;
	if (companyMaxSize === 100) return 0;
	if (companyMaxSize === 1500) return 100;
	if (companyMaxSize === 2000) return 1500;
};
