import {forwardRef} from 'react';
import {Primitive} from '@radix-ui/react-primitive';
import {twMerge} from 'tailwind-merge';

import type {PrimitivePropsWithRef} from '@radix-ui/react-primitive';

export type SkeletonProps = PrimitivePropsWithRef<'div'>;
const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(({className, ...props}, forwardedRef) => (
	<Primitive.div
		className={twMerge('animate-pulse rounded-md bg-mauve7', className)}
		ref={forwardedRef}
		{...props}
	/>
));

export default Skeleton;
