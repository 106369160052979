import {useTrackedJob} from '~/app/(unauthenticated)/job-board/_/hooks/use-tracked-job';
import {PATHS} from '~/shared/constants/paths';
import {Avatar, Card, IconButton, Skeleton, TextButton} from '@job-ish/ui/components';
import {IconArrowBack, IconArrowLeft, IconBookmarkEdit, IconBookmarkPlus} from '@tabler/icons-react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useRouter} from 'next/navigation';

import type {JobRead} from '@job-ish/job-data-api/client/job-data-api';
import {JobBoardListingHeaderSummary} from './summary';

dayjs.extend(relativeTime);

export type JobBoardListingHeaderProps = {
	job: JobRead;
	iconsOnly?: boolean;
	showBackButton?: boolean;
};

export const JobBoardListingHeader = ({job, iconsOnly, showBackButton}: JobBoardListingHeaderProps) => {
	const {trackedJob, handleTrackJob} = useTrackedJob({job});
	const router = useRouter();

	return (
		<Card.Header className="w-full flex-col">
			{showBackButton && (
				<>
					<TextButton
						as="div"
						className="mr-auto flex lg:hidden"
						disabled={!job}
						iconLeft={IconArrowBack}
						onPress={() => {
							router.prefetch(PATHS.JobBoard);
							router.push(PATHS.JobBoard);
						}}
						size="sm"
					>
						Back to Board
					</TextButton>
					<TextButton
						as="div"
						className="mr-auto hidden lg:flex"
						disabled={!job}
						iconLeft={IconArrowLeft}
						onPress={() => {
							router.prefetch(PATHS.JobBoard);
							router.push(PATHS.JobBoard);
						}}
						size="sm"
					>
						Collapse
					</TextButton>
				</>
			)}
			<div className="flex w-full items-start justify-start gap-2">
				<Avatar className="shrink-0 rounded-sm border border-mauve7" size="xl">
					{job.company.logo && (
						<Avatar.Image
							alt={`${job.company.name} logo`}
							className="rounded-sm"
							referrerPolicy="no-referrer"
							src={job.company.logo}
						/>
					)}
					<Avatar.Fallback>
						<Skeleton className="h-full w-full rounded-none" />
					</Avatar.Fallback>
				</Avatar>
				<div className="flex flex-col items-start overflow-hidden text-left">
					<JobBoardListingHeaderSummary job={job} />
				</div>
				{iconsOnly && (
					<IconButton
						aria-label="Add job to kanban board"
						as="div"
						className="-mt-1.5 ml-auto"
						icon={trackedJob ? IconBookmarkEdit : IconBookmarkPlus}
						intent="ghost"
						onPress={() => handleTrackJob()}
						size="sm"
					/>
				)}
			</div>
		</Card.Header>
	);
};
