import {Avatar, Card, Skeleton} from '@job-ish/ui/components';

export const JobBoardLoadingItem = () => (
	<Card
		bordered
		className="w-full cursor-pointer gap-2 overflow-hidden shadow-sm shadow-mauve9"
		role="row"
		shadow
	>
		<Card.Header className="w-full flex-col">
			<div className="flex w-full items-start justify-start gap-2">
				<Avatar className="shrink-0 rounded-sm border border-mauve7" size="xl">
					<Avatar.Fallback>
						<Skeleton className="h-full w-full rounded-none" />
					</Avatar.Fallback>
				</Avatar>
				<div className="flex flex-col items-start gap-1 overflow-hidden text-left">
					<Card.Title className="block w-full items-end gap-1.5 truncate leading-snug">
						<div className="truncate">
							<Skeleton className="h-4 w-72 max-w-full" />
						</div>
					</Card.Title>
					<Card.Description asChild>
						<div className="w-full">
							<Skeleton className="h-4 w-24" />
						</div>
					</Card.Description>
					<Skeleton className="h-3 w-16" />
				</div>
			</div>
			<Skeleton className="mt-1 h-4 w-72 max-w-full self-start" />
		</Card.Header>
	</Card>
);
