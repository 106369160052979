import {useMemo} from 'react';
import {useTrackedJob} from '~/app/(unauthenticated)/job-board/_/hooks/use-tracked-job';
import {Preview} from '~/shared/components/cards/job/preview';
import {JOB_STATUS, jobExits, jobStatuses, jobStatusGroups} from '~/shared/constants/job';
import {Badge} from '@job-ish/ui/components';
import {IconGhost} from '@tabler/icons-react';
import dayjs from 'dayjs';

import type {JobRead} from '@job-ish/job-data-api/client/job-data-api';
import type {BadgeProps} from '@job-ish/ui/components';

export type JobBoardListingLabelsProps = {
	job: JobRead & {expired?: boolean};
};

export const JobBoardListingLabels = ({job}: JobBoardListingLabelsProps) => {
	const {trackedJob} = useTrackedJob({job});

	const trackedJobStatus = useMemo(() => {
		if (!trackedJob) return null;
		return trackedJob.exit_status ? jobExits[trackedJob.exit_status] : jobStatuses[trackedJob.status];
	}, [trackedJob]);

	const trackedJobStatusGroup = useMemo(() => {
		if (!trackedJobStatus?.group) return null;
		return trackedJob?.exit_status
			? jobExits[trackedJob.exit_status]
			: jobStatusGroups[trackedJobStatus.group];
	}, [trackedJob?.exit_status, trackedJobStatus?.group]);

	const ghosted = useMemo(
		() =>
			trackedJob &&
			dayjs().diff(dayjs(trackedJob.applied_at), 'day') > 45 &&
			trackedJob.status === JOB_STATUS.ApplicationSubmitted,
		[trackedJob],
	);

	const maxSalary = useMemo(() => {
		if (job.salary_min && job.salary_max && Number(job.salary_min) > Number(job.salary_max)) {
			return null;
		}
		return job.salary_max ? Number(job.salary_max) : null;
	}, [job.salary_max, job.salary_min]);

	return (
		<>
			{trackedJobStatus && trackedJobStatusGroup && (
				<Badge
					className="min-w-0 shrink"
					color={trackedJobStatusGroup?.color as BadgeProps['color']}
					size="sm"
				>
					{trackedJobStatus.label}
				</Badge>
			)}
			{ghosted && (
				<Badge
					className="min-w-0 shrink"
					color="warning"
					prefix={<IconGhost className="h-4 w-4" />}
					size="sm"
				>
					Ghosted
				</Badge>
			)}
			{job.expired && (
				<Badge className="min-w-0 shrink" color="danger" size="sm">
					Expired
				</Badge>
			)}
			<Preview
				currency={
					job.salary_currency === '' || job.salary_currency === null ? undefined : job.salary_currency
				}
				job={{
					location: job.location,
					remote: JSON.stringify(job.has_remote) as 'true' | 'false',
					min_salary: job.salary_min ? Number(job.salary_min) : null,
					max_salary: maxSalary,
					min_hourly_rate: null,
					max_hourly_rate: null,
					type: job.types.at(0)?.name || null,
				}}
			/>
		</>
	);
};
