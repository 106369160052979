import {useMemo} from 'react';
import {Anchor, Card, Tooltip} from '@job-ish/ui/components';
import {IconExternalLink} from '@tabler/icons-react';
import dayjs from 'dayjs';

import type {JobRead} from '@job-ish/job-data-api/client/job-data-api';

export type JobBoardListingHeaderSummaryProps = {
	job: JobRead;
};

export const JobBoardListingHeaderSummary = ({job}: JobBoardListingHeaderSummaryProps) => {
	const datePublished = useMemo(() => {
		const localDatePublished = dayjs.utc(job.published).local();
		return localDatePublished.isAfter(dayjs()) ? 'Just now' : localDatePublished.fromNow();
	}, [job.published]);

	return (
		<>
			<Card.Title
				asChild
				className="line-clamp-2 w-full items-end gap-1.5 text-sm leading-snug"
				title={job.title}
			>
				<h2>{job.title}</h2>
			</Card.Title>
			<Card.Description asChild>
				<div className="w-full">
					{job.company.website_url ? (
						<Anchor
							className="w-fit max-w-full overflow-hidden text-sm font-medium leading-relaxed text-mauve11 visited:text-mauve11"
							href={job.company.website_url}
							rel="noopener noreferrer"
							suffix={<IconExternalLink className="h-3 w-3 shrink-0" />}
							target="_blank"
						>
							<div className="truncate text-sm font-medium">{job.company.name}</div>
						</Anchor>
					) : (
						<span>{job.company.name}</span>
					)}
				</div>
			</Card.Description>
			{job.published && (
				<Tooltip>
					<Tooltip.Trigger asChild>
						<span className="w-fit text-xs leading-tight text-mauve11" suppressHydrationWarning>
							{datePublished}
						</span>
					</Tooltip.Trigger>
					<Tooltip.Content>
						{dayjs.utc(job.published).local().format('MMMM D, YYYY [at] h:mm A')}
					</Tooltip.Content>
				</Tooltip>
			)}
		</>
	);
};
