export const PREFFIX_TO_FILTER_KEY = {
	keyword: 'keyword:',
	title: 'title:',
	exclude: '-',
} as const;

export const PREFIX_TO_FILTER_LABEL = {
	keyword: 'Keyword',
	title: 'Job Title',
	exclude: 'Exclude',
} as const;

export const PREFIX_TO_PLACEHOLDER = {
	keyword: 'Search a keyword or phrase...',
	title: 'Search a job title...',
	exclude: 'Exclude results containing...',
} as const;
