import {Subscribe} from '~/app/(authenticated)/subscribe/_/components/subscribe';
import {PATHS} from '~/shared/constants/paths';
import {useSubscription} from '~/shared/data/subscription';
import {Button, CloseButton, Dialog, TextButton} from '@job-ish/ui/components';
import NextLink from 'next/link';

export type SubscriptionDialogProps = {
	open: boolean;
	onOpenChange: (open: boolean) => void;
};

export const SubscriptionDialog = ({open, onOpenChange}: SubscriptionDialogProps) => {
	const {data: subscription} = useSubscription();

	return (
		<Dialog onOpenChange={onOpenChange} open={open}>
			<Dialog.Content
				className="max-w-none md:min-w-[600px] lg:min-w-[800px] [&>*:last-child>*]:bg-mauve2"
				closeTrigger={<CloseButton className="z-50" size="sm" />}
			>
				<Dialog.Title>
					<span className="sr-only">Subscribe</span>
				</Dialog.Title>
				<Dialog.Description className="sr-only">
					Subscribe to job-ish to get access to all the features.
				</Dialog.Description>
				<Subscribe
					action={
						subscription ? null : (
							<Button
								as={NextLink}
								className="mt-4 w-full"
								color="primary"
								href={PATHS.Subscribe}
								onPress={() => onOpenChange(false)}
							>
								Sign Up
							</Button>
						)
					}
				>
					<div className="mx-auto mt-1 text-sm text-mauve11">
						or{' '}
						<TextButton
							as={NextLink}
							className="inline-flex"
							color="primary"
							href={`${PATHS.Demo}${PATHS.Dashboard}`}
							size="sm"
							target="_blank"
						>
							view a demo account
						</TextButton>
					</div>
				</Subscribe>
			</Dialog.Content>
		</Dialog>
	);
};
